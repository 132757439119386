<template>
  <el-container>
    <el-aside width="auto">
      <span class="myinput">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
      </span>
      <span class="mytree">
        <el-tree
          v-if="loadFinish === true"
          :highlight-current="highlightCurrent"
          :data="data"
          :props="defaultProps"
          @node-click="handleNodeClick"
          ref="tree"
          accordion
          :filter-node-method="filterNode"
        ></el-tree>
      </span>
    </el-aside>
    <el-main>
      <div class="myselect">
        <el-row :span="24" :gutter="10">
          <el-col :span="4">
            <span class="page">程序编号</span>
          </el-col>
          <el-col :span="20">
            <el-pagination
              :currentPage="curProgId"
              pager-count="20"
              layout="prev, pager, next, jumper"
              :page-count="totalProgId"
              @current-change="handleSelectChange"
            />
          </el-col>
        </el-row>

        <!-- <el-select v-model="value" placeholder="请选择程序编号" @change="onSelectChange(value)">
          <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->

        <div class="mytab">
          <el-tabs tab-position="left">
            <el-tab-pane label="程序信息">
              <div class="progtable">
                <el-table
                  :data="proginfo"bgmys
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 1"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="programid"
                    label="程序编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progname"
                    label="程序名称"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progloopsetcount"
                    label="程序外循环次数"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progendmode"
                    label="程序结束模式"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="proglinkno"
                    label="程序链接组别号"
                  >
                  </el-table-column>
                </el-table>

                <el-table
                  :data="proginfo"
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 2"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="programid"
                    label="程序编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progname"
                    label="程序名称"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progloopsetcount"
                    label="程序外循环次数"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="mode"
                    label="程序运行模式"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="startmode"
                    label="程序启动模式"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="progendmode"
                    label="程序结束模式"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="prehttemp"
                    label="预热温度"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="prelttemp"
                    label="预冷温度"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="frosttemp"
                    label="除霜温度"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="frostperiod"
                    label="除霜周期"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="frosttime"
                    label="除霜时间"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="htreadyflag"
                    label="高温室准备"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="ltreadyflag"
                    label="低温室准备"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="frreadyflag"
                    label="除霜准备"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="changwentemp"
                    label="回常温温度"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="changwentime"
                    label="回常温时间"
                  >
                  </el-table-column>
                </el-table>
                <div class="box">
                  <div id="container" class="container" ref="progprechart"></div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="段次信息">
              <div class="segtable" v-if="devicetypemodel === 1">
                <el-row :span="24" :gutter="10">
                  <el-col :span="8">
                    <span class="seginput">
                      <el-input v-model="search" placeholder="输入关键字进行过滤" />
                    </span>
                  </el-col>
                </el-row>
                <el-row :span="24" :gutter="10">
                  <el-col :span="16">
                    <span>
                      <el-pagination
                        :currentPage="cursegindex"
                        pager-count="10"
                        layout="prev, pager, next, jumper"
                        :page-count="totalsegindex"
                        @current-change="handleSegChange"
                      />
                    </span>
                  </el-col>
                </el-row>
                <!-- <el-button type="success" size="medium" icon="el-icon-caret-left" circle @click='segprevPage()'>
                </el-button>
                <span class="page">第 {{this.cursegindex}} 页 | 共 {{this.totalsegindex}} 页</span>
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle @click='segnextPage()'>
                </el-button> -->

                <el-table
                  :data="
                    subseginfo.filter(
                      (data) =>
                        !search ||
                        data.segwait.toLowerCase().includes(search.toLowerCase()) ||
                        data.segslope
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.segts1
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.segts2
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.segts3
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.segts4
                          .toString()
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        data.seghourminsec.toLowerCase().includes(search.toLowerCase())
                    )
                  "
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                >
                  <el-table-column
                    type="index"
                    align="center"
                    header-align="center"
                    label="索引号"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    header-align="center"
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.chname"
                  >
                  </el-table-column>
                </el-table>
              </div>

              <div class="segtable" v-if="devicetypemodel === 2">
                <el-row :span="24" :gutter="10">
                  <el-col :span="8">
                    <span class="seginput">
                      <el-input v-model="htsearch" placeholder="输入关键字进行过滤" />
                    </span>
                  </el-col>
                </el-row>
                <el-row :span="24" :gutter="10">
                  <el-col :span="4">
                    <span class="page">高温室</span>
                  </el-col>
                  <el-col :span="16">
                    <span>
                      <el-pagination
                        :currentPage="curhtsegindex"
                        pager-count="10"
                        layout="prev, pager, next, jumper"
                        :page-count="totalhtsegindex"
                        @current-change="hthandleSegChange"
                      />
                    </span>
                  </el-col>
                </el-row>
                <!-- <span class="seginput">
                  <el-input v-model="htsearch" placeholder="输入关键字进行过滤" />
                </span>
                <el-button type="success" size="medium" icon="el-icon-caret-left" circle @click='seghtprevPage()'>
                </el-button>
                <span class="page">第 {{this.curhtsegindex}} 页 | 共 {{this.totalhtsegindex}} 页</span>
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle @click='seghtnextPage()'>
                </el-button> -->

                <el-table
                  :data="
                    subhtseginfo.filter(
                      (data) =>
                        !htsearch ||
                        data.segwait.toLowerCase().includes(htsearch.toLowerCase()) ||
                        data.segslope
                          .toString()
                          .toLowerCase()
                          .includes(htsearch.toLowerCase()) ||
                        data.segts1
                          .toString()
                          .toLowerCase()
                          .includes(htsearch.toLowerCase()) ||
                        data.segts2
                          .toString()
                          .toLowerCase()
                          .includes(htsearch.toLowerCase()) ||
                        data.segts3
                          .toString()
                          .toLowerCase()
                          .includes(htsearch.toLowerCase()) ||
                        data.segts4
                          .toString()
                          .toLowerCase()
                          .includes(htsearch.toLowerCase()) ||
                        data.seghourminsec.toLowerCase().includes(htsearch.toLowerCase())
                    )
                  "
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                >
                  <el-table-column
                    type="index"
                    align="center"
                    header-align="center"
                    label="索引号"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    header-align="center"
                    v-for="(item, index) in titlehtList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.chname"
                  >
                  </el-table-column>
                </el-table>
              </div>

              <div class="segtable" v-if="devicetypemodel === 2">
                <el-row :span="24" :gutter="10">
                  <el-col :span="8">
                    <span class="seginput">
                      <el-input v-model="rtsearch" placeholder="输入关键字进行过滤" />
                    </span>
                  </el-col>
                </el-row>
                <el-row :span="24" :gutter="10">
                  <el-col :span="4">
                    <span class="page">常温室</span>
                  </el-col>
                  <el-col :span="16">
                    <span>
                      <el-pagination
                        :currentPage="currtsegindex"
                        pager-count="10"
                        layout="prev, pager, next, jumper"
                        :page-count="totalrtsegindex"
                        @current-change="rthandleSegChange"
                      />
                    </span>
                  </el-col>
                </el-row>
                <!-- <span class="seginput">
                  <el-input v-model="rtsearch" placeholder="输入关键字进行过滤" />
                </span>
                <el-button type="success" size="medium" icon="el-icon-caret-left" circle @click='segrtprevPage()'>
                </el-button>
                <span class="page">第 {{this.currtsegindex}} 页 | 共 {{this.totalrtsegindex}} 页</span>
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle @click='segrtnextPage()'>
                </el-button> -->

                <el-table
                  :data="
                    subrtseginfo.filter(
                      (data) =>
                        !rtsearch ||
                        data.segwait.toLowerCase().includes(rtsearch.toLowerCase()) ||
                        data.segslope
                          .toString()
                          .toLowerCase()
                          .includes(rtsearch.toLowerCase()) ||
                        data.segts1
                          .toString()
                          .toLowerCase()
                          .includes(rtsearch.toLowerCase()) ||
                        data.segts2
                          .toString()
                          .toLowerCase()
                          .includes(rtsearch.toLowerCase()) ||
                        data.segts3
                          .toString()
                          .toLowerCase()
                          .includes(rtsearch.toLowerCase()) ||
                        data.segts4
                          .toString()
                          .toLowerCase()
                          .includes(rtsearch.toLowerCase()) ||
                        data.seghourminsec.toLowerCase().includes(rtsearch.toLowerCase())
                    )
                  "
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                >
                  <el-table-column
                    type="index"
                    align="center"
                    header-align="center"
                    label="索引号"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    header-align="center"
                    v-for="(item, index) in titlertList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.chname"
                  >
                  </el-table-column>
                </el-table>
              </div>

              <div class="segtable" v-if="devicetypemodel === 2">
                <!-- <span class="seginput">
                  <el-input v-model="ltsearch" placeholder="输入关键字进行过滤" />
                </span>
                <el-button type="success" size="medium" icon="el-icon-caret-left" circle @click='segltprevPage()'>
                </el-button>
                <span class="page">第 {{this.curltsegindex}} 页 | 共 {{this.totalltsegindex}} 页</span>
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle @click='segltnextPage()'>
                </el-button> -->
                <el-row :span="24" :gutter="10">
                  <el-col :span="8">
                    <span class="seginput">
                      <el-input v-model="ltsearch" placeholder="输入关键字进行过滤" />
                    </span>
                  </el-col>
                </el-row>
                <el-row :span="24" :gutter="10">
                  <el-col :span="4">
                    <span class="page">低温室</span>
                  </el-col>
                  <el-col :span="16">
                    <span>
                      <el-pagination
                        :currentPage="curltsegindex"
                        pager-count="10"
                        layout="prev, pager, next, jumper"
                        :page-count="totalltsegindex"
                        @current-change="lthandleSegChange"
                      />
                    </span>
                  </el-col>
                </el-row>

                <el-table
                  :data="
                    subltseginfo.filter(
                      (data) =>
                        !ltsearch ||
                        data.segwait.toLowerCase().includes(ltsearch.toLowerCase()) ||
                        data.segslope
                          .toString()
                          .toLowerCase()
                          .includes(ltsearch.toLowerCase()) ||
                        data.segts1
                          .toString()
                          .toLowerCase()
                          .includes(ltsearch.toLowerCase()) ||
                        data.segts2
                          .toString()
                          .toLowerCase()
                          .includes(ltsearch.toLowerCase()) ||
                        data.segts3
                          .toString()
                          .toLowerCase()
                          .includes(ltsearch.toLowerCase()) ||
                        data.segts4
                          .toString()
                          .toLowerCase()
                          .includes(ltsearch.toLowerCase()) ||
                        data.seghourminsec.toLowerCase().includes(ltsearch.toLowerCase())
                    )
                  "
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                >
                  <el-table-column
                    type="index"
                    align="center"
                    header-align="center"
                    label="索引号"
                    width="100"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    header-align="center"
                    v-for="(item, index) in titleltList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.chname"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="循环信息">
              <div class="looptable">
                <!-- <el-button type="success" size="medium" icon="el-icon-caret-left" circle @click='loopprevPage()'>
                </el-button>
                <span class="page">第 {{this.curloopindex}} 页 | 共 {{this.totalloopindex}} 页</span>
                <el-button type="warning" size="medium" icon="el-icon-caret-right" circle @click='loopnextPage()'>
                </el-button> -->
                <el-table
                  :data="subloopinfo"
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 1"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="loopid"
                    label="循环编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="startsegid"
                    label="起始段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="endsegid"
                    label="结束段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="totalloopcount"
                    label="循环次数"
                  >
                  </el-table-column>
                </el-table>
                <span class="page" v-if="devicetypemodel === 2">高温室</span>
                <el-table
                  :data="subhtloopinfo"
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 2"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="loopid"
                    label="循环编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="startsegid"
                    label="起始段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="endsegid"
                    label="结束段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="totalloopcount"
                    label="循环次数"
                  >
                  </el-table-column>
                </el-table>
                <span class="page" v-if="devicetypemodel === 2">常温室</span>
                <el-table
                  :data="subrtloopinfo"
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 2"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="loopid"
                    label="循环编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="startsegid"
                    label="起始段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="endsegid"
                    label="结束段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="totalloopcount"
                    label="循环次数"
                  >
                  </el-table-column>
                </el-table>
                <span class="page" v-if="devicetypemodel === 2">低温室</span>
                <el-table
                  :data="subltloopinfo"
                  style="width: 100%"
                  :border="border"
                  highlight-current-row
                  v-if="devicetypemodel === 2"
                >
                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="loopid"
                    label="循环编号"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="startsegid"
                    label="起始段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="endsegid"
                    label="结束段"
                  >
                  </el-table-column>

                  <el-table-column
                    align="center"
                    header-align="center"
                    prop="totalloopcount"
                    label="循环次数"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getProgramInfo, getTitleNoList, getDeviceInfo } from "../../axios/data.js";

import Highcharts from "highcharts/highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighNoDataToDisplay from "highcharts/modules/no-data-to-display";
import Highmaps from "highcharts/modules/map";
import HighExporting from "highcharts/modules/exporting";
import HighExportData from "highcharts/modules/export-data";
import HighOffLineExporting from "highcharts/modules/offline-exporting";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import Highcharts3D from "highcharts/highcharts-3d";
import HighGridLight from "highcharts/themes/grid-light.js";
//import HighBoost from 'highcharts/modules/boost'

HighchartsMore(Highcharts);
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
Highmaps(Highcharts);
HighNoDataToDisplay(Highcharts);
HighExporting(Highcharts);
HighExportData(Highcharts);
HighOffLineExporting(Highcharts);
HighGridLight(Highcharts);

export default {
  name: "historyalarm",
  data() {
    return {
      curProgId: 1,
      totalProgId: 100,
      show: false,
      clearable: false,
      editable: false,
      loading: null,
      loadText: "",
      titlenoList: [],
      filterText: "",
      data: [],
      loadFinish: false,
      highlightCurrent: true,
      defaultProps: {
        children: "children",
        label: "label",
      },
      curLabel: "",
      border: true,
      search: "",
      htsearch: "",
      rtsearch: "",
      ltsearch: "",
      count: 0,
      proginfo: [],
      seginfo: [],
      loopinfo: [],
      seghtinfo: [],
      loophtinfo: [],
      segrtinfo: [],
      looprtinfo: [],
      segltinfo: [],
      loopltinfo: [],
      titleList: [],
      titlehtList: [],
      titlertList: [],
      titleltList: [],
      chinfo: [],
      subseginfo: [],
      subhtseginfo: [],
      subrtseginfo: [],
      subltseginfo: [],
      value: "",
      options: [],
      curvalue: 1,
      cursegindex: 1,
      totalsegindex: 10,
      curhtvalue: 1,
      curhtsegindex: 1,
      totalhtsegindex: 5,
      currtvalue: 1,
      currtsegindex: 1,
      totalrtsegindex: 5,
      curltvalue: 1,
      curltsegindex: 1,
      totalltsegindex: 5,
      curloopindex: 1,
      totalloopindex: 1,
      curhtloopindex: 1,
      totalhtloopindex: 1,
      currtloopindex: 1,
      totalrtloopindex: 1,
      curltloopindex: 1,
      totalltloopindex: 1,
      subloopinfo: [],
      subhtloopinfo: [],
      subrtloopinfo: [],
      subltloopinfo: [],
      validSegData: [],
      validLoopData: [],
      validhtSegData: [],
      validhtLoopData: [],
      validrtSegData: [],
      validrtLoopData: [],
      validltSegData: [],
      validltLoopData: [],
      execSegmentList: [],
      exechtSegmentList: [],
      execrtSegmentList: [],
      execltSegmentList: [],
      chnameList: [],
      chart: null,
      devicetypemodel: 0,
    };
  },
  watch: {
    filterText(val) {
      if (this.$refs.tree !== undefined) {
        this.$refs.tree.filter(val);
      }
    },
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.$refs.progprechart.style.width = 70 + "vw";
      this.$refs.progprechart.style.height = 55 + "vh";
      this.refreshStyle();
    },
    windowWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.$refs.progprechart.style.width = 70 + "vw";
      this.$refs.progprechart.style.height = 55 + "vh";
      this.refreshStyle();
    },
  },
  computed: {
    domain() {
      return this.$store.state.tab.domain;
    },
    domain443() {
      return this.$store.state.tab.domain443;
    },
    windowWidth() {
      return this.$store.state.tab.windowWidth;
    },
    windowHeight() {
      return this.$store.state.tab.windowHeight;
    },
    titleno() {
      return this.$store.state.tab.titleno;
    },
    titlelist() {
      return this.$store.state.user.titlelist;
    },
  },
  mounted() {
    this.onMounted();
    this.refreshStyle();
  },
  methods: {
    checkStar(titleList) {
      return titleList.indexOf("*") !== -1;
    },
    refreshStyle() {
      var Myselect = document.querySelectorAll(".myselect");
      for (var i = 0; i < Myselect.length; i++) {
        Myselect[i].style.height = 85 + "vh";
      }
    },
    openFullScreen() {
      this.loading = this.$loading({
        lock: true,
        text: this.loadText,
        spinner: "el-icon-loading",
        customClass: "create-isLoading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    closeFullScreen() {
      this.loading.close();
    },
    async getTitleList() {
      this.loadText = "正在向服务器请求数据, 请稍候~~";
      this.openFullScreen();
      try {
        const res = await getTitleNoList(this.domain);
        var respText = JSON.parse(res.request.responseText);

        if (respText.status === "true") {
          var isArray = respText.message.constructor === Array;
          if (isArray) {
            for (var i = 0; i < respText.message.length; i++) {
              let isvalid = this.checkStar(this.titlelist);
              if (!isvalid) {
                isvalid = this.titlelist.indexOf(respText.message[i].titleno) !== -1;
              }
              if (isvalid) {
                this.titlenoList.push(respText.message[i].titleno);
              }
            }
          } else {
            var isObject = respText.message.constructor === Object;
            if (isObject) {
              let isvalid = this.checkStar(this.titlelist);
              if (!isvalid) {
                isvalid = this.titlelist.indexOf(respText.message.titleno) !== -1;
              }
              if (isvalid) {
                this.titlenoList.push(respText.message.titleno);
              }
            }
          }
        }

        for (i = 0; i < this.titlenoList.length; i++) {
          const yearstring = this.titlenoList[i].substring(0, 4);
          const year = parseInt(yearstring);
          if (year >= 2000 && year <= 2100) {
            var isexist = false,
              j = 0;
            for (j = 0; j < this.data.length; j++) {
              if (this.data[j].label === yearstring) {
                isexist = true;
                break;
              }
            }

            if (!isexist) {
              var obj = {
                label: yearstring,
                year: yearstring,
                children: [
                  {
                    label: this.titlenoList[i],
                    year: yearstring,
                    children: [],
                  },
                ],
              };
              this.data.push(obj);
            } else {
              obj = {
                label: this.titlenoList[i],
                year: yearstring,
                children: [],
              };
              this.data[j].children.push(obj);
            }
          }
        }
        this.loadFinish = true;
        if (this.titleno === "") {
          if (this.data.length > 0) {
            if (this.data[0].children && this.data[0].children.length > 0)
              var titleno = this.data[0].children[0].label;
            let params = {};
            params.titleno = titleno;
            //this.titleno = titleno
            this.$store.commit("updateTitleNo", titleno);
            this.filterText = titleno;
            try {
              const res = await getDeviceInfo(this.domain, params);
              respText = JSON.parse(res.request.responseText);
              this.count = 0;
              this.chinfo = [];
              if (respText.status === "true") {
                this.count = respText.message.count;
                this.chinfo = JSON.parse(respText.message.chinfo);
                let commoninfo = JSON.parse(respText.message.commoninfo);
                this.devicetypemodel = commoninfo.devicetypemodel;
                this.initProgSelection();
                await this.getProgramInfo(titleno);
                this.closeFullScreen();
              } else {
                this.closeFullScreen();
              }
            } catch (error) {
              this.$message.warning(error);
              this.closeFullScreen();
            }
          } else {
            this.closeFullScreen();
          }
        } else {
          let params = {};
          params.titleno = this.titleno;
          this.filterText = this.titleno;
          try {
            const res = await getDeviceInfo(this.domain, params);
            respText = JSON.parse(res.request.responseText);
            this.count = 0;
            this.chinfo = [];
            if (respText.status === "true") {
              this.count = respText.message.count;
              this.chinfo = JSON.parse(respText.message.chinfo);
              let commoninfo = JSON.parse(respText.message.commoninfo);
              this.devicetypemodel = commoninfo.devicetypemodel;
              this.initProgSelection();
              await this.getProgramInfo(this.titleno);
              this.closeFullScreen();
            } else {
              this.closeFullScreen();
            }
          } catch (error) {
            this.$message.warning(error);
            this.closeFullScreen();
          }
        }
      } catch (error) {
        this.$message.warning(error);
        this.closeFullScreen();
      }
    },
    onMounted() {
      this.initCharts();
      this.getTitleList();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async handleNodeClick(data) {
      if (data.label.length === 4) {
        var year = parseInt(data.label);
        if (year >= 2000 && year <= 2100) return;
      }

      if (data.label === this.titleno) return;
      //this.titleno = data.label
      this.value = "1";
      this.curvalue = 1;
      this.cursegindex = 1;
      this.curloopindex = 1;
      //this.titleno = data.label
      console.log("program store commit");
      this.$store.commit("updateTitleNo", data.label);
      try {
        let params = {};
        params.titleno = data.label;
        const res = await getDeviceInfo(this.domain, params);
        var respText = JSON.parse(res.request.responseText);
        this.count = 0;
        this.chinfo = [];
        if (respText.status === "true") {
          this.count = respText.message.count;
          this.chinfo = JSON.parse(respText.message.chinfo);
          let commoninfo = JSON.parse(respText.message.commoninfo);
          this.devicetypemodel = commoninfo.devicetypemodel;
          this.initProgSelection();
          await this.getProgramInfo(this.titleno);
          this.closeFullScreen();
        } else {
          this.closeFullScreen();
        }
      } catch (error) {
        this.$message.warning(error);
        this.closeFullScreen();
      }
      //this.getProgramInfo(this.titleno)
      this.filterText = "";
    },
    async getProgramInfo(titleno) {
      this.openFullScreen();
      this.proginfo = [];
      this.seginfo = [];
      this.loopinfo = [];
      this.seghtinfo = [];
      this.loophtinfo = [];
      this.segrtinfo = [];
      this.looprtinfo = [];
      this.segltinfo = [];
      this.loopltinfo = [];
      this.titleList = [];
      this.titlehtList = [];
      this.titlertList = [];
      this.titleltList = [];
      this.chnameList = [];
      this.validSegData = [];
      this.validLoopData = [];
      this.execSegmentList = [];
      this.validhtSegData = [];
      this.validhtLoopData = [];
      this.exechtSegmentList = [];
      this.validrtSegData = [];
      this.validrtLoopData = [];
      this.execrtSegmentList = [];
      this.validltSegData = [];
      this.validltLoopData = [];
      this.execltSegmentList = [];
      try {
        var params = new Object();
        params.titleno = titleno;
        params.programid = this.curvalue;
        var res = await getProgramInfo(this.domain, params);
        this.loadText = "正在向服务器请求数据, 请稍候~~";

        var respText = JSON.parse(res.request.responseText);
        //console.log(respText)
        if (respText.status === "true") {
          var programinfo = JSON.parse(respText.message.programinfo);
          //console.log(programinfo)
          if (this.devicetypemodel === 1) {
            var proginfo = programinfo.proginfo;

            proginfo.progendmode =
              proginfo.progendmode === 0
                ? "停止"
                : proginfo.progendmode === 1
                ? "链接"
                : "回常温";
            proginfo.programid = params.programid;
            this.proginfo.push(proginfo);
            var seginfo = programinfo.seginfo;
            //console.log('programinfo.loopinfo', programinfo.loopinfo)
            this.loopinfo = programinfo.loopinfo;
            //console.log("original", this.loopinfo)

            //console.log(this.loopinfo)
            {
              let obj = new Object();
              obj.chname = "段编号";
              obj.prop = `segid`;
              this.titleList.push(obj);
            }
            var index = 0;
            for (var i = 0; i < this.chinfo.length; i++) {
              //console.log('this.chinfo[i].chctlflag', this.chinfo[i].chctlflag)
              if (this.chinfo[i].chctlflag == true) {
                let obj = new Object();
                obj.chname = this.chinfo[i].chname;
                obj.prop = `segch${i + 1}`;
                this.titleList.push(obj);
                ++index;
              }
            }
            //console.log('index', index)
            for (var j = index; j < 10; j++) {
              for (var k = 0; k < seginfo.length; k++) {
                for (var item in seginfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete seginfo[k][`segch${j + 1}`];
                  }
                }
              }
            }

            for (k = 0; k < seginfo.length; k++) {
              var hour = seginfo[k].seghour;
              var min = seginfo[k].segmin;
              var sec = seginfo[k].segsec;
              var array = [];
              array.push(hour);
              array.push(min);
              array.push(sec);
              seginfo[k].segid = k + 1;
              seginfo[k].seghourminsec = array.join(".");
              delete seginfo[k].seghour;
              delete seginfo[k].segmin;
              delete seginfo[k].segsec;
              seginfo[k].segwait = seginfo[k].segwait === true ? "是" : "否";
              seginfo[k].segslope = seginfo[k].segslope === true ? "是" : "否";
            }

            {
              let obj = new Object();
              obj.chname = `时.分.秒`;
              obj.prop = "seghourminsec";
              this.titleList.push(obj);
            }

            for (i = 0; i < 4; i++) {
              let obj = new Object();
              obj.chname = `时序开关${i + 1}`;
              obj.prop = `segts${i + 1}`;
              this.titleList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `等待`;
              obj.prop = "segwait";
              this.titleList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `线性模式`;
              obj.prop = "segslope";
              this.titleList.push(obj);
            }
            this.seginfo = seginfo;
            this.subseginfo = [];
            this.subseginfo = seginfo.filter(
              (item) =>
                item.segid >= 10 * (this.cursegindex - 1) + 1 &&
                item.segid <= 10 * this.cursegindex
            );
            this.subloopinfo = [];
            this.subloopinfo = this.loopinfo.filter(
              (item) =>
                item.loopid >= 10 * (this.curloopindex - 1) + 1 &&
                item.loopid <= 10 * this.curloopindex
            );
            this.getValidInfo();
            this.reflowCurve();
          } else {
            proginfo = programinfo.proginfo;

            proginfo.progendmode =
              proginfo.progendmode === 0
                ? "停止"
                : proginfo.progendmode === 0
                ? "除霜"
                : "回常温";
            proginfo.startmode =
              proginfo.startmode === 1
                ? "高温室-低温室"
                : proginfo.startmode === 2
                ? "低温室-高温室"
                : (proginfo.startmode =
                    proginfo.startmode === 3
                      ? "高温室-低温室(高温室结束)"
                      : proginfo.startmode === 4
                      ? "低温室-高温室(低温室结束)"
                      : proginfo.startmode === 5
                      ? "常温室-高温室-常温室-低温室"
                      : proginfo.startmode === 6
                      ? "常温室-低温室-常温室-高温室"
                      : proginfo.startmode === 7
                      ? "高温室-常温室-低温室-常温室"
                      : "低温室-常温室-高温室-常温室");
            proginfo.mode =
              proginfo.mode === 0
                ? "高温室运行"
                : proginfo.mode === 1
                ? "低温室运行"
                : "高低温冲击";
            proginfo.htreadyflag = proginfo.htreadyflag === true ? "开启" : "关闭";
            proginfo.ltreadyflag = proginfo.ltreadyflag === true ? "开启" : "关闭";
            proginfo.frreadyflag = proginfo.frreadyflag === true ? "开启" : "关闭";
            proginfo.programid = params.programid;
            this.proginfo.push(proginfo);
            var seghtinfo = programinfo.seghtinfo;
            var segrtinfo = programinfo.segrtinfo;
            //console.log('segrtinfo', segrtinfo)
            var segltinfo = programinfo.segltinfo;
            //console.log('programinfo.loopinfo', programinfo.loopinfo)
            this.loophtinfo = programinfo.loophtinfo;
            this.looprtinfo = programinfo.looprtinfo;
            this.loopltinfo = programinfo.loopltinfo;

            {
              let obj = new Object();
              obj.chname = "段编号";
              obj.prop = `segid`;
              this.titlehtList.push(obj);
            }
            index = 2;
            if (this.chinfo.length >= 2) {
              if (this.chinfo[1].chctlflag == true) {
                let obj = new Object();
                obj.chname = this.chinfo[1].chname;
                obj.prop = `segch${1 + 1}`;
                this.titlehtList.push(obj);
              }
            }
            for (j = 0; j < 1; j++) {
              for (k = 0; k < seghtinfo.length; k++) {
                for (item in seghtinfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete seghtinfo[k][`segch${j + 1}`];
                  }
                }
              }
            }
            for (j = index; j < 10; j++) {
              for (k = 0; k < seghtinfo.length; k++) {
                for (item in seghtinfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete seghtinfo[k][`segch${j + 1}`];
                  }
                }
              }
            }

            for (k = 0; k < seghtinfo.length; k++) {
              hour = seghtinfo[k].seghour;
              min = seghtinfo[k].segmin;
              sec = seghtinfo[k].segsec;
              array = [];
              array.push(hour);
              array.push(min);
              array.push(sec);
              seghtinfo[k].segid = k + 1;
              seghtinfo[k].seghourminsec = array.join(".");
              delete seghtinfo[k].seghour;
              delete seghtinfo[k].segmin;
              delete seghtinfo[k].segsec;
              seghtinfo[k].segwait = seghtinfo[k].segwait === true ? "是" : "否";
              seghtinfo[k].segslope = seghtinfo[k].segslope === true ? "是" : "否";
            }

            {
              let obj = new Object();
              obj.chname = `时.分.秒`;
              obj.prop = "seghourminsec";
              this.titlehtList.push(obj);
            }

            for (i = 0; i < 4; i++) {
              let obj = new Object();
              obj.chname = `时序开关${i + 1}`;
              obj.prop = `segts${i + 1}`;
              this.titlehtList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `等待`;
              obj.prop = "segwait";
              this.titlehtList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `线性模式`;
              obj.prop = "segslope";
              this.titlehtList.push(obj);
            }
            this.seghtinfo = seghtinfo;
            this.subhtseginfo = [];
            this.subhtseginfo = seghtinfo.filter(
              (item) =>
                item.segid >= 10 * (this.curhtsegindex - 1) + 1 &&
                item.segid <= 10 * this.curhtsegindex
            );
            this.subhtloopinfo = [];
            this.subhtloopinfo = this.loophtinfo.filter(
              (item) =>
                item.loopid >= 10 * (this.curhtloopindex - 1) + 1 &&
                item.loopid <= 10 * this.curhtloopindex
            );

            {
              let obj = new Object();
              obj.chname = "段编号";
              obj.prop = `segid`;
              this.titlertList.push(obj);
            }
            index = 1;
            if (this.chinfo.length >= 1) {
              if (this.chinfo[0].chctlflag == true) {
                let obj = new Object();
                obj.chname = "常温室温度";
                obj.prop = `segch${0 + 1}`;
                this.titlertList.push(obj);
              }
            }

            for (j = index; j < 10; j++) {
              for (k = 0; k < segrtinfo.length; k++) {
                for (item in segrtinfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete segrtinfo[k][`segch${j + 1}`];
                  }
                }
              }
            }

            for (k = 0; k < segrtinfo.length; k++) {
              hour = segrtinfo[k].seghour;
              min = segrtinfo[k].segmin;
              sec = segrtinfo[k].segsec;
              array = [];
              array.push(hour);
              array.push(min);
              array.push(sec);
              segrtinfo[k].segid = k + 1;
              segrtinfo[k].seghourminsec = array.join(".");
              delete segrtinfo[k].seghour;
              delete segrtinfo[k].segmin;
              delete segrtinfo[k].segsec;
              segrtinfo[k].segwait = segrtinfo[k].segwait === true ? "是" : "否";
              segrtinfo[k].segslope = segrtinfo[k].segslope === true ? "是" : "否";
            }

            {
              let obj = new Object();
              obj.chname = `时.分.秒`;
              obj.prop = "seghourminsec";
              this.titlertList.push(obj);
            }

            for (i = 0; i < 4; i++) {
              let obj = new Object();
              obj.chname = `时序开关${i + 1}`;
              obj.prop = `segts${i + 1}`;
              this.titlertList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `等待`;
              obj.prop = "segwait";
              this.titlertList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `线性模式`;
              obj.prop = "segslope";
              this.titlertList.push(obj);
            }
            this.segrtinfo = segrtinfo;
            console.log("segrtinfo", segrtinfo);
            this.subrtseginfo = [];
            this.subrtseginfo = segrtinfo.filter(
              (item) =>
                item.segid >= 10 * (this.currtsegindex - 1) + 1 &&
                item.segid <= 10 * this.currtsegindex
            );
            this.subrtloopinfo = [];
            this.subrtloopinfo = this.looprtinfo.filter(
              (item) =>
                item.loopid >= 10 * (this.currtloopindex - 1) + 1 &&
                item.loopid <= 10 * this.currtloopindex
            );

            {
              let obj = new Object();
              obj.chname = "段编号";
              obj.prop = `segid`;
              this.titleltList.push(obj);
            }
            index = 3;
            if (this.chinfo.length >= 3) {
              if (this.chinfo[2].chctlflag == true) {
                let obj = new Object();
                obj.chname = this.chinfo[2].chname;
                obj.prop = `segch${2 + 1}`;
                this.titleltList.push(obj);
              }
            }

            for (j = 0; j < 2; j++) {
              for (k = 0; k < segltinfo.length; k++) {
                for (item in segltinfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete segltinfo[k][`segch${j + 1}`];
                  }
                }
              }
            }

            for (j = index; j < 10; j++) {
              for (k = 0; k < segltinfo.length; k++) {
                for (item in segltinfo[k]) {
                  if (item === `segch${j + 1}`) {
                    delete segltinfo[k][`segch${j + 1}`];
                  }
                }
              }
            }

            for (k = 0; k < segltinfo.length; k++) {
              hour = segltinfo[k].seghour;
              min = segltinfo[k].segmin;
              sec = segltinfo[k].segsec;
              array = [];
              array.push(hour);
              array.push(min);
              array.push(sec);
              segltinfo[k].segid = k + 1;
              segltinfo[k].seghourminsec = array.join(".");
              delete segltinfo[k].seghour;
              delete segltinfo[k].segmin;
              delete segltinfo[k].segsec;
              segltinfo[k].segwait = segltinfo[k].segwait === true ? "是" : "否";
              segltinfo[k].segslope = segltinfo[k].segslope === true ? "是" : "否";
            }

            {
              let obj = new Object();
              obj.chname = `时.分.秒`;
              obj.prop = "seghourminsec";
              this.titleltList.push(obj);
            }

            for (i = 0; i < 4; i++) {
              let obj = new Object();
              obj.chname = `时序开关${i + 1}`;
              obj.prop = `segts${i + 1}`;
              this.titleltList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `等待`;
              obj.prop = "segwait";
              this.titleltList.push(obj);
            }

            {
              let obj = new Object();
              obj.chname = `线性模式`;
              obj.prop = "segslope";
              this.titleltList.push(obj);
            }
            this.segltinfo = segltinfo;
            console.log("segltinfo", segltinfo);
            this.subltseginfo = [];
            this.subltseginfo = segltinfo.filter(
              (item) =>
                item.segid >= 10 * (this.curltsegindex - 1) + 1 &&
                item.segid <= 10 * this.curltsegindex
            );
            this.subltloopinfo = [];
            this.subltloopinfo = this.loopltinfo.filter(
              (item) =>
                item.loopid >= 10 * (this.currtloopindex - 1) + 1 &&
                item.loopid <= 10 * this.currtloopindex
            );

            this.gethtrtltValidInfo();
            this.reflowhrlCurve();
          }
        }
        this.closeFullScreen();
      } catch (error) {
        this.closeFullScreen();
      }
    },
    getValidInfo() {
      var lastEndSegId = 0;
      for (var i = 0; i < this.seginfo.length; i++) {
        if (this.seginfo[i].seghourminsec !== "0.0.0") {
          this.validSegData.push(this.seginfo[i]);
        } else {
          break;
        }
      }

      for (i = 0; i < this.loopinfo.length; i++) {
        var innerLoopData = this.loopinfo[i];
        if (
          innerLoopData.totalloopcount > 0 &&
          innerLoopData.startsegid > lastEndSegId &&
          innerLoopData.startsegid < this.validSegData.length &&
          innerLoopData.endsegid > innerLoopData.startsegid &&
          innerLoopData.endsegid <= this.validSegData.length
        ) {
          this.validLoopData.push(innerLoopData);
          lastEndSegId = innerLoopData.endsegid;
        }
      }
      //console.log('validloopdata', this.validLoopData)
      this.execSegmentList = [];
      if (this.validLoopData.length > 0) {
        lastEndSegId = 0;
        this.validLoopData.forEach((innerLoopData) => {
          var startSegId = innerLoopData.startsegid;
          var endSegId = innerLoopData.endsegid;

          // 循环前序列
          if (startSegId > lastEndSegId) {
            for (var segId = lastEndSegId + 1; segId < startSegId; segId++) {
              this.execSegmentList.push(segId);
            }
          }
          var looplist = [];
          for (segId = startSegId; segId <= endSegId; segId++) {
            looplist.push(segId);
          }

          for (i = 0; i < innerLoopData.totalloopcount; i++) {
            this.execSegmentList = this.execSegmentList.concat(looplist);
          }
          lastEndSegId = endSegId;
        });
        // 循环后序列
        for (segId = lastEndSegId + 1; segId <= this.validSegData.length; segId++) {
          this.execSegmentList.push(segId);
        }
      } else {
        for (var segId = 1; segId <= this.validSegData.length; segId++) {
          this.execSegmentList.push(segId);
        }
      }

      if (this.proginfo.length > 0) {
        var execSegmentList = this.execSegmentList;
        for (i = 0; i < this.proginfo[0].progloopsetcount - 1; i++) {
          this.execSegmentList = this.execSegmentList.concat(execSegmentList);
        }
      }

      //console.log(this.execSegmentList)
    },
    gethtrtltValidInfo() {
      var lastEndSegId = 0;
      for (var i = 0; i < this.seghtinfo.length; i++) {
        if (this.seghtinfo[i].seghourminsec !== "0.0.0") {
          this.validhtSegData.push(this.seghtinfo[i]);
        } else {
          break;
        }
      }

      for (i = 0; i < this.loophtinfo.length; i++) {
        var innerLoopData = this.loophtinfo[i];
        if (
          innerLoopData.totalloopcount > 0 &&
          innerLoopData.startsegid > lastEndSegId &&
          innerLoopData.startsegid < this.validhtSegData.length &&
          innerLoopData.endsegid > innerLoopData.startsegid &&
          innerLoopData.endsegid <= this.validhtSegData.length
        ) {
          this.validhtLoopData.push(innerLoopData);
          lastEndSegId = innerLoopData.endsegid;
        }
      }

      this.exechtSegmentList = [];
      if (this.validhtLoopData.length > 0) {
        lastEndSegId = 0;
        this.validhtLoopData.forEach((innerLoopData) => {
          var startSegId = innerLoopData.startsegid;
          var endSegId = innerLoopData.endsegid;

          // 循环前序列
          if (startSegId > lastEndSegId) {
            for (var segId = lastEndSegId + 1; segId < startSegId; segId++) {
              this.exechtSegmentList.push(segId);
            }
          }
          var looplist = [];
          for (segId = startSegId; segId <= endSegId; segId++) {
            looplist.push(segId);
          }

          for (i = 0; i < innerLoopData.totalloopcount; i++) {
            this.exechtSegmentList = this.exechtSegmentList.concat(looplist);
          }
          lastEndSegId = endSegId;
        });
        // 循环后序列
        for (segId = lastEndSegId + 1; segId <= this.validhtSegData.length; segId++) {
          this.exechtSegmentList.push(segId);
        }
      } else {
        for (var segId = 1; segId <= this.validhtSegData.length; segId++) {
          this.exechtSegmentList.push(segId);
        }
      }

      if (this.proginfo.length > 0) {
        if (this.proginfo[0].mode === "高温室运行") {
          var exechtSegmentList = this.exechtSegmentList;
          for (i = 0; i < this.proginfo[0].progloopsetcount - 1; i++) {
            this.exechtSegmentList = this.exechtSegmentList.concat(exechtSegmentList);
          }
        }
      }

      lastEndSegId = 0;
      for (i = 0; i < this.segrtinfo.length; i++) {
        if (this.segrtinfo[i].seghourminsec !== "0.0.0") {
          this.validrtSegData.push(this.segrtinfo[i]);
        } else {
          break;
        }
      }

      for (i = 0; i < this.looprtinfo.length; i++) {
        innerLoopData = this.looprtinfo[i];
        if (
          innerLoopData.totalloopcount > 0 &&
          innerLoopData.startsegid > lastEndSegId &&
          innerLoopData.startsegid < this.validrtSegData.length &&
          innerLoopData.endsegid > innerLoopData.startsegid &&
          innerLoopData.endsegid <= this.validrtSegData.length
        ) {
          this.validrtLoopData.push(innerLoopData);
          lastEndSegId = innerLoopData.endsegid;
        }
      }

      this.execrtSegmentList = [];
      if (this.validrtLoopData.length > 0) {
        lastEndSegId = 0;
        this.validrtLoopData.forEach((innerLoopData) => {
          var startSegId = innerLoopData.startsegid;
          var endSegId = innerLoopData.endsegid;

          // 循环前序列
          if (startSegId > lastEndSegId) {
            for (var segId = lastEndSegId + 1; segId < startSegId; segId++) {
              this.execrtSegmentList.push(segId);
            }
          }
          var looplist = [];
          for (segId = startSegId; segId <= endSegId; segId++) {
            looplist.push(segId);
          }

          for (i = 0; i < innerLoopData.totalloopcount; i++) {
            this.execrtSegmentList = this.execrtSegmentList.concat(looplist);
          }
          lastEndSegId = endSegId;
        });
        // 循环后序列
        for (segId = lastEndSegId + 1; segId <= this.validrtSegData.length; segId++) {
          this.execrtSegmentList.push(segId);
        }
      } else {
        for (segId = 1; segId <= this.validrtSegData.length; segId++) {
          this.execrtSegmentList.push(segId);
        }
      }

      lastEndSegId = 0;
      for (i = 0; i < this.segltinfo.length; i++) {
        if (this.segltinfo[i].seghourminsec !== "0.0.0") {
          this.validltSegData.push(this.segltinfo[i]);
        } else {
          break;
        }
      }

      for (i = 0; i < this.loopltinfo.length; i++) {
        innerLoopData = this.loopltinfo[i];
        if (
          innerLoopData.totalloopcount > 0 &&
          innerLoopData.startsegid > lastEndSegId &&
          innerLoopData.startsegid < this.validltSegData.length &&
          innerLoopData.endsegid > innerLoopData.startsegid &&
          innerLoopData.endsegid <= this.validltSegData.length
        ) {
          this.validltLoopData.push(innerLoopData);
          lastEndSegId = innerLoopData.endsegid;
        }
      }

      this.execltSegmentList = [];
      if (this.validltLoopData.length > 0) {
        lastEndSegId = 0;
        this.validltLoopData.forEach((innerLoopData) => {
          var startSegId = innerLoopData.startsegid;
          var endSegId = innerLoopData.endsegid;

          // 循环前序列
          if (startSegId > lastEndSegId) {
            for (var segId = lastEndSegId + 1; segId < startSegId; segId++) {
              this.execltSegmentList.push(segId);
            }
          }
          var looplist = [];
          for (segId = startSegId; segId <= endSegId; segId++) {
            looplist.push(segId);
          }

          for (i = 0; i < innerLoopData.totalloopcount; i++) {
            this.execltSegmentList = this.execltSegmentList.concat(looplist);
          }
          lastEndSegId = endSegId;
        });
        // 循环后序列
        for (segId = lastEndSegId + 1; segId <= this.validltSegData.length; segId++) {
          this.execltSegmentList.push(segId);
        }
      } else {
        for (segId = 1; segId <= this.validltSegData.length; segId++) {
          this.execltSegmentList.push(segId);
        }
      }

      if (this.proginfo.length > 0) {
        if (this.proginfo[0].mode === "低温室运行") {
          var execltSegmentList = this.execltSegmentList;
          for (i = 0; i < this.proginfo[0].progloopsetcount - 1; i++) {
            this.execltSegmentList = this.execltSegmentList.concat(execltSegmentList);
          }
        }
      }

      console.log(this.exechtSegmentList);
      console.log(this.execrtSegmentList);
      console.log(this.execltSegmentList);
      console.log(this.validrtSegData);
      console.log(this.validhtSegData);
      console.log(this.validltSegData);
    },
    reflowCurve() {
      var eachNameList = [];
      var eachTimeValueList = [];
      var ctlchinfo = this.chinfo.filter((item) => item.chctlflag == true);
      // console.log('ctlchinfo', ctlchinfo)
      // console.log('this.execSegmentList', this.execSegmentList)
      // console.log('this.validsegdata', this.validSegData)
      for (var i = 1; i <= ctlchinfo.length; i++) {
        eachNameList.push(ctlchinfo[i - 1].chname);
        if (!eachTimeValueList[i - 1]) eachTimeValueList[i - 1] = [];
        var totaltime = 0.0;

        if (this.execSegmentList.length > 0) {
          switch (ctlchinfo[i - 1].chtype) {
            case 5:
            case 6:
            case 0:
              eachTimeValueList[i - 1].push([totaltime, 25]);
              break;
            case 2:
              eachTimeValueList[i - 1].push([totaltime, 100]);
              break;
            case 1:
              eachTimeValueList[i - 1].push([totaltime, 100]);
              break;
            case 3:
              eachTimeValueList[i - 1].push([totaltime, 101.3]);
              break;
            case 4:
              eachTimeValueList[i - 1].push([totaltime, 0]);
              break;
          }
          this.execSegmentList.forEach((segId) => {
            this.validSegData.forEach((segData) => {
              if (segId == segData.segid) {
                var contains = false;
                for (var item in segData) {
                  if (item === `segch${i}`) {
                    contains = true;
                    break;
                  }
                }

                if (contains) {
                  if (segData.seghourminsec) {
                    if (eachTimeValueList[i - 1].length >= 200) {
                      return false;
                    }
                    var strList = segData.seghourminsec
                      .split(".")
                      .filter((item) => item !== "");

                    if (strList.length === 3) {
                      if (segData.segslope === "否") {
                        eachTimeValueList[i - 1].push([totaltime, segData[`segch${i}`]]);
                      }

                      totaltime +=
                        parseFloat(strList[0]) +
                        parseFloat(strList[1]) / 60.0 +
                        parseFloat(strList[2]) / 3600.0;
                      eachTimeValueList[i - 1].push([totaltime, segData[`segch${i}`]]);
                    }
                  }
                }
              }
            });
            if (eachTimeValueList[i - 1].length >= 200) {
              return false;
            }
          });
        }
      }
      // console.log("eachNameList", eachNameList)
      // console.log("eachTimeValueList", eachTimeValueList)
      while (this.chart.xAxis.length > 1) {
        this.chart.xAxis[1].remove(false);
      }

      while (this.chart.yAxis.length > 1) {
        this.chart.yAxis[1].remove(false);
      }

      while (this.chart.series.length > 0) {
        this.chart.series[0].remove(false);
      }

      this.chart.redraw();
      this.chart.reflow();
      var xIndex = 0;
      if (this.chart.xAxis[xIndex]) {
        this.chart.xAxis[xIndex].setTitle({ text: "小时(Hour)" }, false);
        this.chart.xAxis[xIndex].update({
          className: "小时(Hour)",
          id: "小时(Hour)",
          gridLineDashStyle: "Dot",
        });
      }
      var yIndex = 0;
      ctlchinfo.forEach((item) => {
        if (yIndex === 0) {
          var lower = item.lower;
          var upper = item.upper;
          if (this.chart.yAxis[yIndex]) {
            this.chart.yAxis[yIndex].setExtremes(lower, upper, false);
            this.chart.yAxis[yIndex].setTitle(
              {
                text:
                  item.chname === "湿球温度"
                    ? "湿度" + item.chunit
                    : item.chname + item.chunit,
              },
              false
            );
            this.chart.yAxis[yIndex].update({
              className: item.chname,
              id: item.chname,
              gridLineDashStyle: "Dot",
            });
          }
          ++yIndex;
        } else {
          lower = item.lower;
          upper = item.upper;
          this.chart.addAxis(
            {
              tickAmount: 4,
              allowDecimals: false,
              opposite: yIndex % 2 === 0 ? false : true,
              lineWidth: 1,
              softMin: -20,
              softMax: 80,
            },
            false,
            false
          );
          if (this.chart.yAxis[yIndex]) {
            this.chart.yAxis[yIndex].setExtremes(lower, upper, false);
            this.chart.yAxis[yIndex].setTitle(
              {
                text:
                  item.chname === "湿球温度" + item.chunit
                    ? "湿度"
                    : item.chname + item.chunit,
              },
              false
            );
            this.chart.yAxis[yIndex].update({
              className: item.chname,
              id: item.chname,
              gridLineDashStyle: "Dot",
            });
          }
          ++yIndex;
        }
      });
      ctlchinfo.forEach((chinfo) => {
        var xAxisIndex = 0,
          yAxisIndex = 0;
        this.chart.xAxis.forEach(function (axis, index) {
          if (axis.userOptions.className == "Time") {
            xAxisIndex = index;
            return false;
          }
        });

        this.chart.yAxis.forEach(function (axis, index) {
          if (axis.userOptions.className == chinfo.chname) {
            yAxisIndex = index;
            return false;
          }
        });
        var chcolor = chinfo.chcolors.split("|").filter((item) => item !== "");

        this.chart.addSeries({
          name: chinfo.chname,
          boostThreshold: 1000,
          turboThreshold: 1,
          data: [],
          xAxis: xAxisIndex,
          yAxis: yAxisIndex,
          color: chcolor[0],
          findNearestPointBy: "x",
          showInLegend: this.showLegend,
        });
      });

      this.chart.series.forEach(function (serie, index) {
        eachNameList.forEach(function (name, index1) {
          var namestring = name.toString();
          if (serie.name.trim() === namestring.trim()) {
            serie.setData(eachTimeValueList[index1], false);
          }
        });
      });

      this.chart.xAxis[0].setExtremes(0.0, totaltime, false);
      setTimeout(() => {
        if (this.chart) {
          this.chart.redraw();
          this.chart.reflow();
          this.chart.hideLoading();
          this.closeFullScreen();
        }
      }, 500);
    },
    reflowhrlCurve() {
      var eachNameList = [];
      var eachTimeValueList = [];

      eachNameList.push("趋势图");
      if (!eachTimeValueList[0]) eachTimeValueList[0] = [];
      var totaltime = 0.0;
      if (this.proginfo[0].mode === "高低温冲击") {
        if (this.proginfo[0].startmode === "高温室-低温室") {
          if (this.exechtSegmentList.length > 0 && this.execltSegmentList.length > 0) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;

              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "低温室-高温室") {
          if (this.exechtSegmentList.length > 0 && this.execltSegmentList.length > 0) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "高温室-低温室(高温室结束)") {
          if (this.exechtSegmentList.length > 0 && this.execltSegmentList.length > 0) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;

              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
            }

            for (let j = 0; j < this.exechtSegmentList.length; j++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let k = 0; k < this.validhtSegData.length; k++) {
                if (eachTimeValueList[0].length >= 200) break;
                if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                  if (this.validhtSegData[k].seghourminsec) {
                    strList = this.validhtSegData[k].seghourminsec
                      .split(".")
                      .filter((item) => item !== "");
                    if (strList.length === 3) {
                      if (this.validhtSegData[k].segslope === "否") {
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                      totaltime +=
                        parseFloat(strList[0]) +
                        parseFloat(strList[1]) / 60.0 +
                        parseFloat(strList[2]) / 3600.0;
                      eachTimeValueList[0].push([
                        totaltime,
                        this.validhtSegData[k][`segch2`],
                      ]);
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "低温室-高温室(低温室结束)") {
          if (this.exechtSegmentList.length > 0 && this.execltSegmentList.length > 0) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }
            }

            for (let j = 0; j < this.execltSegmentList.length; j++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let k = 0; k < this.validltSegData.length; k++) {
                if (eachTimeValueList[0].length >= 200) break;
                if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                  if (this.validltSegData[k].seghourminsec) {
                    strList = this.validltSegData[k].seghourminsec
                      .split(".")
                      .filter((item) => item !== "");
                    if (strList.length === 3) {
                      if (this.validltSegData[k].segslope === "否") {
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                      totaltime +=
                        parseFloat(strList[0]) +
                        parseFloat(strList[1]) / 60.0 +
                        parseFloat(strList[2]) / 3600.0;
                      eachTimeValueList[0].push([
                        totaltime,
                        this.validltSegData[k][`segch3`],
                      ]);
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "常温室-高温室-常温室-低温室") {
          if (
            this.exechtSegmentList.length > 0 &&
            this.execltSegmentList.length > 0 &&
            this.execrtSegmentList.length > 0
          ) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      var strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");

                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "常温室-低温室-常温室-高温室") {
          if (
            this.exechtSegmentList.length > 0 &&
            this.execltSegmentList.length > 0 &&
            this.execrtSegmentList.length > 0
          ) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");

                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }
              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "高温室-常温室-低温室-常温室") {
          if (
            this.exechtSegmentList.length > 0 &&
            this.execltSegmentList.length > 0 &&
            this.execrtSegmentList.length > 0
          ) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;

              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");

                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (this.proginfo[0].startmode === "低温室-常温室-高温室-常温室") {
          if (
            this.exechtSegmentList.length > 0 &&
            this.execltSegmentList.length > 0 &&
            this.execrtSegmentList.length > 0
          ) {
            eachTimeValueList[0].push([totaltime, 25]);
            for (let i = 0; i < this.proginfo[0].progloopsetcount; i++) {
              if (eachTimeValueList[0].length >= 200) break;
              for (let j = 0; j < this.execltSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validltSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                    if (this.validltSegData[k].seghourminsec) {
                      strList = this.validltSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validltSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validltSegData[k][`segch3`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validltSegData[k][`segch3`],
                        ]);
                      }
                    }
                  }
                }
              }
              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.exechtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validhtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                    if (this.validhtSegData[k].seghourminsec) {
                      strList = this.validhtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");
                      if (strList.length === 3) {
                        if (this.validhtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validhtSegData[k][`segch2`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validhtSegData[k][`segch2`],
                        ]);
                      }
                    }
                  }
                }
              }

              for (let j = 0; j < this.execrtSegmentList.length; j++) {
                if (eachTimeValueList[0].length >= 200) break;
                for (let k = 0; k < this.validrtSegData.length; k++) {
                  if (eachTimeValueList[0].length >= 200) break;
                  if (this.execrtSegmentList[j] == this.validrtSegData[k].segid) {
                    if (this.validrtSegData[k].seghourminsec) {
                      strList = this.validrtSegData[k].seghourminsec
                        .split(".")
                        .filter((item) => item !== "");

                      if (strList.length === 3) {
                        if (this.validrtSegData[k].segslope === "否") {
                          eachTimeValueList[0].push([
                            totaltime,
                            this.validrtSegData[k][`segch1`],
                          ]);
                        }
                        totaltime +=
                          parseFloat(strList[0]) +
                          parseFloat(strList[1]) / 60.0 +
                          parseFloat(strList[2]) / 3600.0;
                        eachTimeValueList[0].push([
                          totaltime,
                          this.validrtSegData[k][`segch1`],
                        ]);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else if (this.proginfo[0].mode === "高温室运行") {
        if (this.exechtSegmentList.length > 0) {
          eachTimeValueList[0].push([totaltime, 25]);
          for (let j = 0; j < this.exechtSegmentList.length; j++) {
            if (eachTimeValueList[0].length >= 200) break;
            for (let k = 0; k < this.validhtSegData.length; k++) {
              if (eachTimeValueList[0].length >= 200) break;
              if (this.exechtSegmentList[j] == this.validhtSegData[k].segid) {
                if (this.validhtSegData[k].seghourminsec) {
                  strList = this.validhtSegData[k].seghourminsec
                    .split(".")
                    .filter((item) => item !== "");
                  if (strList.length === 3) {
                    if (this.validhtSegData[k].segslope === "否") {
                      eachTimeValueList[0].push([
                        totaltime,
                        this.validhtSegData[k][`segch2`],
                      ]);
                    }
                    totaltime +=
                      parseFloat(strList[0]) +
                      parseFloat(strList[1]) / 60.0 +
                      parseFloat(strList[2]) / 3600.0;
                    eachTimeValueList[0].push([
                      totaltime,
                      this.validhtSegData[k][`segch2`],
                    ]);
                  }
                }
              }
            }
          }
        }
      } else if (this.proginfo[0].mode === "低温室运行") {
        if (this.execltSegmentList.length > 0) {
          eachTimeValueList[0].push([totaltime, 25]);
          for (let j = 0; j < this.execltSegmentList.length; j++) {
            if (eachTimeValueList[0].length >= 200) break;
            for (let k = 0; k < this.validltSegData.length; k++) {
              if (eachTimeValueList[0].length >= 200) break;
              if (this.execltSegmentList[j] == this.validltSegData[k].segid) {
                if (this.validltSegData[k].seghourminsec) {
                  strList = this.validltSegData[k].seghourminsec
                    .split(".")
                    .filter((item) => item !== "");
                  if (strList.length === 3) {
                    if (this.validltSegData[k].segslope === "否") {
                      eachTimeValueList[0].push([
                        totaltime,
                        this.validltSegData[k][`segch3`],
                      ]);
                    }
                    totaltime +=
                      parseFloat(strList[0]) +
                      parseFloat(strList[1]) / 60.0 +
                      parseFloat(strList[2]) / 3600.0;
                    eachTimeValueList[0].push([
                      totaltime,
                      this.validltSegData[k][`segch3`],
                    ]);
                  }
                }
              }
            }
          }
        }
      }

      while (this.chart.xAxis.length > 1) {
        this.chart.xAxis[1].remove(false);
      }

      while (this.chart.yAxis.length > 1) {
        this.chart.yAxis[1].remove(false);
      }

      while (this.chart.series.length > 0) {
        this.chart.series[0].remove(false);
      }

      this.chart.redraw();
      this.chart.reflow();
      this.chart.xAxis[0].setTitle({ text: "小时(Hour)" }, false);
      this.chart.xAxis[0].update({
        className: "小时(Hour)",
        id: "小时(Hour)",
        gridLineDashStyle: "Dot",
      });
      //var lower = -9999, upper = 9999

      var lower = -80;
      var upper = 160;

      this.chart.yAxis[0].setExtremes(lower, upper, false);
      this.chart.yAxis[0].setTitle({ text: "趋势图" }, false);
      this.chart.yAxis[0].update({
        className: "趋势图",
        id: "趋势图",
        gridLineDashStyle: "Dot",
      });

      // var xAxisIndex = 0, yAxisIndex = 0;
      // this.chart.xAxis.forEach(function (axis, index) {
      //     xAxisIndex = index;
      //     return false;
      // })

      // this.chart.yAxis.forEach(function (axis, index) {
      //     yAxisIndex = index;
      //     return false;
      // })
      var chcolor = "#00AAFF";

      this.chart.addSeries({
        name: "趋势图",
        boostThreshold: 1000,
        turboThreshold: 1,
        data: [],
        xAxis: 0,
        yAxis: 0,
        color: chcolor,
        findNearestPointBy: "x",
        showInLegend: this.showLegend,
      });

      this.chart.series.forEach(function (serie, index) {
        eachNameList.forEach(function (name, index1) {
          var namestring = name.toString();
          if (serie.name.trim() === namestring.trim()) {
            serie.setData(eachTimeValueList[index1], false);
          }
        });
      });

      this.chart.xAxis[0].setExtremes(0.0, totaltime, false);
      setTimeout(() => {
        if (this.chart) {
          this.chart.redraw();
          this.chart.reflow();
          this.chart.hideLoading();
          this.closeFullScreen();
        }
      }, 500);
    },
    initSelection() {
      this.options = [];
      let programcount =
        this.devicetypemodel === 1 ? 100 : this.devicetypemodel === 2 ? 50 : 100;
      for (let i = 0; i < programcount; i++) {
        let obj = {
          value: (i + 1).toString(),
          label: (i + 1).toString(),
        };
        this.options.push(obj);
      }

      this.value = "1";
      this.curvalue = 1;
    },
    initProgSelection() {
      this.totalProgId =
        this.devicetypemodel === 1 ? 100 : this.devicetypemodel === 2 ? 50 : 100;
      this.value = "1";
      this.curvalue = 1;
    },
    onSelectChange(val) {
      var curval = parseInt(val);
      if (curval !== this.curvalue) {
        this.curvalue = curval;
        this.cursegindex = 1;
        this.curloopindex = 1;
        this.curhtsegindex = 1;
        this.curhtloopindex = 1;
        this.currtsegindex = 1;
        this.currtloopindex = 1;
        this.curltsegindex = 1;
        this.curltloopindex = 1;
        this.getProgramInfo(this.titleno);
        //this.refreshCurve(this.lastClickFileInfo.filename)
      }
    },
    handleSelectChange(val) {
      var curval = val;
      if (curval !== this.curvalue) {
        this.curvalue = curval;
        this.cursegindex = 1;
        this.curloopindex = 1;
        this.curhtsegindex = 1;
        this.curhtloopindex = 1;
        this.currtsegindex = 1;
        this.currtloopindex = 1;
        this.curltsegindex = 1;
        this.curltloopindex = 1;
        this.getProgramInfo(this.titleno);
        //this.refreshCurve(this.lastClickFileInfo.filename)
      }
    },
    refreshSeg() {
      this.subseginfo = [];
      this.subseginfo = this.seginfo.filter(
        (item) =>
          item.segid >= 10 * (this.cursegindex - 1) + 1 &&
          item.segid <= 10 * this.cursegindex
      );
    },
    refreshhtSeg() {
      this.subhtseginfo = [];
      this.subhtseginfo = this.seghtinfo.filter(
        (item) =>
          item.segid >= 10 * (this.curhtsegindex - 1) + 1 &&
          item.segid <= 10 * this.curhtsegindex
      );
    },
    refreshrtSeg() {
      this.subrtseginfo = [];
      this.subrtseginfo = this.segrtinfo.filter(
        (item) =>
          item.segid >= 10 * (this.currtsegindex - 1) + 1 &&
          item.segid <= 10 * this.currtsegindex
      );
    },
    refreshltSeg() {
      this.subltseginfo = [];
      this.subltseginfo = this.segltinfo.filter(
        (item) =>
          item.segid >= 10 * (this.curltsegindex - 1) + 1 &&
          item.segid <= 10 * this.curltsegindex
      );
    },
    handleSegChange(val) {
      this.cursegindex = val;
      this.refreshSeg();
    },
    segnextPage() {
      if (this.cursegindex < this.totalsegindex) {
        ++this.cursegindex;

        this.refreshSeg();
      }
    },
    segprevPage() {
      if (this.cursegindex > 1) {
        --this.cursegindex;
        this.refreshSeg();
      }
    },
    hthandleSegChange(val) {
      this.curhtsegindex = val;
      this.refreshhtSeg();
    },
    seghtnextPage() {
      if (this.curhtsegindex < this.totalhtsegindex) {
        ++this.curhtsegindex;

        this.refreshhtSeg();
      }
    },
    seghtprevPage() {
      if (this.curhtsegindex > 1) {
        --this.curhtsegindex;
        this.refreshhtSeg();
      }
    },
    rthandleSegChange(val) {
      this.currtsegindex = val;
      this.refreshrtSeg();
    },
    segrtnextPage() {
      if (this.currtsegindex < this.totalrtsegindex) {
        ++this.currtsegindex;

        this.refreshrtSeg();
      }
    },
    segrtprevPage() {
      if (this.currtsegindex > 1) {
        --this.currtsegindex;
        this.refreshrtSeg();
      }
    },
    lthandleSegChange(val) {
      this.curltsegindex = val;
      this.refreshltSeg();
    },
    segltnextPage() {
      if (this.curltsegindex < this.totalltsegindex) {
        ++this.curltsegindex;

        this.refreshltSeg();
      }
    },
    segltprevPage() {
      if (this.curltsegindex > 1) {
        --this.curltsegindex;
        this.refreshltSeg();
      }
    },
    refreshLoop() {
      console.log("refreshLoop", this.loopinfo);
      this.subloopinfo = [];
      this.subloopinfo = this.loopinfo.filter(
        (item) =>
          item.loopid >= 5 * (this.curloopindex - 1) + 1 &&
          item.loopid <= 5 * this.curloopindex
      );
    },
    loopnextPage() {
      if (this.curloopindex < this.totalloopindex) {
        ++this.curloopindex;
        this.refreshLoop();
      }
    },
    loopprevPage() {
      if (this.curloopindex > 1) {
        --this.curloopindex;
        this.refreshLoop();
      }
    },
    initCharts() {
      this.$refs.progprechart.style.width = 70 + "vw";
      this.$refs.progprechart.style.height = 55 + "vh";
      Highcharts.setOptions({
        global: {
          useUTC: false,
        },
        lang: {
          rangeSelectorZoom: "范围选择",
          downloadPNG: "下载PNG图片",
          downloadJPEG: "下载JPEG图片",
          downloadSVG: "下载SVG图片",
          downloadCSV: "下载CSV文件",
          downloadPDF: "下载PDF文件",
          printChart: "打印曲线",
          noData: "当前没有数据",
          loading: "正在努力加载数据, 请稍候！",
          resetZoom: "复位初始状态",
          viewFullscreen: "全屏观看",
          exitFullscreen: "退出全屏",
        },
      });

      this.chart = Highcharts.chart({
        chart: {
          renderTo: "container",
          type: "line",
          animation: false,
          zoomType: "x",
          style: {
            fontFamily: "Helvetica, sans-serif, 'Microsoft YaHei'",
            fontSize: "0.833vw",
            color: "#8990A7",
          },
          events: {
            selection: function (event) {
              // 打印 x 轴最大值及最小值（即范围）
              if (event.resetSelection) {
                this.xAxis[0].setExtremes(
                  event.target.xAxis[0].min,
                  event.target.xAxis[0].max,
                  false
                );
                this.redraw();
                this.reflow();
              }
            },
            click: function (e) {
              this.redraw();
              this.reflow();
            },
          },
        },
        title: {
          text: null,
        },
        legend: { enabled: true },
        credits: { enabled: false },
        noData: {
          style: {
            fontSize: "0.938vw",
            fontWeight: "bold",
            color: "#8990A7",
          },
        },
        navigator: {
          height: 30,
          margin: 10,
        },
        exporting: {
          filename: Highcharts.dateFormat("%Y%m%d_%H%M%S", new Date().getTime()),
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "separator",
                "downloadPNG",
                "separator",
                "downloadJPEG",
                "separator",
                "downloadSVG",
                "separator",
                "downloadCSV",
                "separator",
                "printChart",
              ],
            },
          },
        },
        mapNavigation: {
          enabled: true,
          enableButtons: false,
        },
        tooltip: {
          split: false,
          shared: true,
          animation: false,
          xDateFormat: "%y-%m-%d %H:%M:%S",
          useHTML: true,
          headerFormat:
            '<span style="color: #DA1C5D; font-size: 0.625vw;">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name}: </td>' +
            '<td style="text-align: right; padding-left: 0.521vw;">{point.y}</td>{valueSuffix}',
          footerFormat: "</tr></table>",
          valueDecimals: 1,
          crosshairs: {
            width: 2,
            color: "gray",
            dashStyle: "shortdot",
          },
        },
        xAxis: {},
        yAxis: {
          tickAmount: 4,
          allowDecimals: false,
          opposite: false,
          lineWidth: 1,
          softMin: -20,
          softMax: 80,
        },
        boost: {
          seriesThreshold: 1,
        },
        plotOptions: {
          series: {
            lineWidth: 1,
            animation: false,
            findNearestPointBy: "x",
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: true,
                lineWidth: 1,
                halo: {
                  size: 5,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-info {
  margin-right: 0.521vw;
  font-size: 2.083vw;
  transform: translateY(0.26vw);
}

.myinput ::v-deep {
  .el-input {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .el-input__inner {
    font-size: 14px;
    color: #333;
    background-color: aqua;
    border: 1px solid red;
  }
}

.segtable .seginput ::v-deep {
  .el-input {
    margin-top: 20px;
    margin-bottom: 10px;
    // right: 20px;
    margin-left: 1.042vw;
    width: 15.625vw;
  }
  .el-input__inner {
    font-size: 0.729vw;
    color: #333;
    background-color: #1f1f1f;
    border: 1px solid red;
    font-family: cursive;
    width: 15.625vw;
  }
}

.myselect {
  margin-bottom: 0.521vw;
  // 未选中任何选项的时候 placeholder的样式 需要先选中父元素 增加权重
  ::v-deep input::-webkit-input-placeholder {
    color: #fff;
  }
  ::v-deep input::-moz-input-placeholder {
    color: #fff;
  }
  ::v-deep input::-ms-input-placeholder {
    color: #fff;
  }

  //修改的是el-input的样式
  //一种方法是设置最里层el-input__inner的背景色 外层的两级父元素设置为透明色
  //另一种方法是从el-select到el-input__inenr的背景色都设置为需要的颜色
  ::v-deep .el-select,
  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    background-color: darkcyan;
    color: #fff;
    border: none;
    border-radius: 0.26vw;
    text-align: center;
    margin-right: 1.563vw;
    width: 8.333vw;
  }

  //el-input聚焦的时候 外层的border会有一个样式
  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border: 0;
  }

  //修改的是el-input上下的小图标的颜色
  ::v-deep .el-select .el-input .el-select__caret {
    color: #fff;
  }

  //修改总体选项的样式 最外层
  ::v-deep .el-select-dropdown {
    background-color: #08164d;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  //修改单个的选项的样式
  ::v-deep .el-select-dropdown__item {
    background-color: transparent;
    color: #fff;
  }

  //item选项的hover样式
  ::v-deep .el-select-dropdown__item.hover,
  ::v-deep .el-select-dropdown__item:hover {
    color: #409eff;
  }

  //修改的是下拉框选项内容上方的尖角
  ::v-deep .el-popper .popper__arrow,
  .el-popper .popper__arrow::after {
    display: none;
  }

  ::v-deep .el-pagination {
    background-color: #1f1f1f;
  }
  ::v-deep .el-pager li {
    background-color: transparent;
    color: white;
    &:hover {
      color: lightgreen;
    }
  }
    

  ::v-deep .el-pager li.active {
    color: #409Eff
  }

  ::v-deep button {
    color: white;
    background-color: transparent;
}
}

.mytree ::v-deep {
  overflow: hidden;
  .el-tree-node {
    position: relative;
    padding-left: 0;
  }
  //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree-node__label {
    width: auto;
    float: right;
    font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 10px;
    color: #1f1f1f;
    line-height: 1.302vw;
    letter-spacing: 0.052vw;
    &:hover {
      color: white;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: pink; //背景色
  }
  .el-tree-node__content {
    &:hover {
      background: lightgreen;
      .el-tree-node__label {
        color: white;
      }
    }
  }

  .el-tree .el-icon-caret-right:before {
    content: "\e723";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.26vw;
  }
  .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "\e722";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.26vw;
  }
}

.segtable ::v-deep,
.progtable ::v-deep,
.looptable ::v-deep {
  .el-table .el-table__header-wrapper tr th {
    background-color: rgb(18, 47, 92) !important;
    color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }
  .el-table .el-table__row {
    // background-color: rgb(18, 47, 92);
    // color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }

  .el-table .el-table__body tr.current-row > td {
    background-color: lightblue !important;
    color: white;
  }
  .el-table .el-table__body tr:hover > td {
    background-color: lightgreen !important;
    color: red;
  }

  .el-table__header tr,
  .el-table__header th {
    height: 2.083vw;
    padding: 0;
  }
  .el-table__body tr,
  .el-table__body td {
    height: 2.083vw;
    padding: 0;
  }

  .el-table {
    margin-top: 0.521vw;
  }

  .el-table th {
    display: table-cell !important;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .el-table::before {
    height: 0;
  }
}

.progtable ::v-deep {
  margin-top: 1.042vw;
  .box {
    background-color: #fff;
    margin-top: 0.521vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container {
      border: 1px solid lightseagreen;
    }
  }
}

.looptable ::v-deep {
  margin-top: 1.042vw;
}


.el-main {
  padding-top: 0px;
  background-color: #1389bc;
}
.el-container {
  height: 100%;
  background-color: #333;
  border: 1px solid #333;
  margin-top: 0.042vw;
  margin-bottom: 1.042vw;
}

.page {
  color: #1f1f1f;
  border-radius: 0.26vw;
  margin: 1.042vw;
  padding: 0.521vw 1.042vw;
  width: 10.417vw;
  height: 1.563vw;
  line-height: 1.563vw;
}

.mytab ::v-deep {
  .el-tabs__item {
    margin-top: 15px;
    padding: -1px 0.521vw;
    margin-bottom: 15px;
    
    // border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    line-height: 5;
    font-size: 12px;
    font-weight: 400;
    background-color: aquamarine;
    color: #333;
    text-align: center;
    border-radius: 10px;
  }

  .el-tabs__active-bar {
     background-color: transparent;
}

  .el-tabs__item.is-active {
    color: green; //批改激活表头字体色彩，默认是蓝色
    background-color: aqua;
    border-radius: 10px;
    border-bottom: 2px solid red;
  }

  .el-tabs__item:hover {
    height: 60px;
    text-align: center;
    line-height: 5;
    border-radius: 10px;
    color: orangered; //批改鼠标挪动到表头时候字体色彩，默认淡蓝色
    cursor: pointer; //鼠标挪动到表头时候鼠标款式，默认小手
    background-color: pink;
  }

}
</style>

<style lang="scss">
.create-isLoading {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 1.042vw;
    border-radius: 0.208vw;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #eee;
    }

    .el-loading-text {
      color: #dbe276;
      font-size: 0.833vw;
    }
  }
}

.el-pagination {
    white-space: nowrap;
    padding: 2px 5px;
    margin-left: -40px;
    color: #1f1f1f;
    font-weight: 700;
}

.el-pagination__jump {
    margin-left: 24px;
    font-weight: 400;
    color:#1f1f1f;
}

.el-tabs__content {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
}
</style>
